import './App.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import BitcoinPrice from './BitcoinPrice';
function App() {
  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon/>
          </IconButton>
          
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>

      <Typography>

        <BitcoinPrice />

      </Typography>

      <Typography>
      <br/><br/><br/>
        Henry sucks at trading

      </Typography>

      {/* <CardList></CardList> */}
    </div>
  );
}

export default App;
