import React, { useState, useEffect } from 'react';
import './BitcoinPrice.css';
const BitcoinPrice = () => {
  // State variable to store the Bitcoin price
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  // Function to fetch Bitcoin price from the API
  const fetchBitcoinPrice = async () => {
    try {
      const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice.json');
      const data = await response.json();
      // Extract the Bitcoin price from the API response
      const price = data.bpi.USD.rate;
      // Set the Bitcoin price in the state variable
      setBitcoinPrice(price);
    } catch (error) {
      console.error('Error fetching Bitcoin price:', error);
    }
  };

  // Function to fetch Bitcoin price every 0.5 seconds
  useEffect(() => {
    
    const intervalId = setInterval(fetchBitcoinPrice, 500);
    
    // Clear interval when the component unmounts or changes
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <div className='bitcoinDiv'>
      <h2>Bitcoin Price:</h2>
      {bitcoinPrice ? (
        <p className="rainbow-text">${bitcoinPrice} USD</p>
      ) : (
        <p >Loading...</p>
      )}
    </div>
  );
};

export default BitcoinPrice;
